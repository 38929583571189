import React, { useEffect } from 'react';
import { ResponsiveSwarmPlot } from '@nivo/swarmplot'
import EmptyStateText from '../EmptyStateText'
import { formattedLeftTickLabel, formattedNumberLabel } from '../../util/formattedLabels'
import { Grid, Paper, Typography } from '@mui/material'
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';

function HireStarsSwarmPlot({ data, reset, handleResetSwarm, swarmCounties}) {
    const colorMap = {
        average: '#8C4799',
        custom: '#FFB81C'
    }

    const populationValues = data.map(el => el.population)

    const SwarmLabel = (nodes) => {
        let swarmLabels = nodes.nodes.map(({ data, x, y }) => {
            let color = "white"
            if(data.node_type === "custom"){
                color = "black"
            }
            return (
                <text
                    key={data.group+"-label"+data.id}
                    x = {x}
                    y = {y + 4}
                    fontSize={10}
                    fill={color} 
                    fontFamily="DM Sans"
                    style={{ pointerEvents: 'none', textAnchor: 'middle' }}
                >
                    {Math.round(data.population).toLocaleString('en-US')}
                </text>
            )
        })
        return <g>{swarmLabels}</g>
    }

    function customTooltip(node){
        return (
            <Paper elevation={1} 
                style={{
                    padding: '0.25rem 0.5rem', 
                    borderRadius:"4px",
                    position:'absolute',
                    whiteSpace: 'nowrap',
                    right: node.x>175? 0 : null,
                    top:0
                }}>
                <Typography variant="subtitle2" style={{fontWeight: 700}}>
                    {node.group}
                </Typography>
                <Grid container>
                    <Grid item alignItems="center">
                        <SquareRoundedIcon style={{color: node.color, fontSize:"1rem", marginBottom: "-0.2rem"}} />
                        <Typography display="inline" style={{paddingLeft:"0.25rem", fontWeight: 400, fontSize:"0.75rem"}}>{(node.id.includes("custom")? "Custom: ": "Default: ") + formattedNumberLabel(node.data.population)}</Typography>
                    </Grid>   
                </Grid>
            </Paper>
        )
    }

  function removeExtraGroups(object) {
    return swarmCounties.includes(object.group)
  }

    useEffect(() => {
        if (reset) {
            handleResetSwarm(false)
        }
    // eslint-disable-next-line
    }, [reset]);

    return (
        data.length>0
        ? 
            <div style={{height:"34rem", overflowX: "auto"}}>
                <div style={{height: `${swarmCounties.length * 6 + 5.285}rem`,marginTop: '1rem'}}>
                    <ResponsiveSwarmPlot
                        data={data.filter(removeExtraGroups)}
                        colors={(({id, data}) => colorMap[data.node_type])}
                        groups={swarmCounties}
                        identity="id"
                        value="population"
                        valueFormat={".2s"}
                        tooltip={node => customTooltip(node)}
                        valueScale={{ 
                            type: 'symlog', 
                            constant: 1e3, 
                            // made min and max 90% and 110% of value respectively - helps bubbles from
                            // running off the edge of the line
                            min: ((Math.min(...populationValues))*.9),
                            max: (Math.max(...populationValues)*1.1), 
                            reverse: false 
                        }}
                        layout="horizontal"
                        forceStrength={.1}
                        gap={100}
                        size={{
                            key: 'volume',
                            values: [
                                0,
                                100
                            ],
                            sizes: [
                                40,
                                80
                            ]
                        }}
                        simulationIterations={100}
                        borderColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    0.6
                                ],
                                [
                                    'opacity',
                                    0.5
                                ]
                            ]
                        }}
                        margin={{ top: 120, right: 80, bottom: 80, left: 150 }}
                        axisBottom={false}
                        enableGridY={true}
                        enableGridX={false}
                        axisRight={false}
                        axisLeft={{
                            orient: 'bottom',
                            tickSize: 0,
                            tickPadding: 45,
                            tickRotation: 0,
                            format: defaultLabel => formattedLeftTickLabel(defaultLabel, 15)
                        }}
                        axisTop={false}
                        layers = {[
                            'grid', 
                            'axes', 
                            'circles', 
                            SwarmLabel, 
                            'annotations', 
                            'mesh'
                        ]}
                    />
                </div>
            </div>
        :   
            <EmptyStateText/>
    )
}

export default HireStarsSwarmPlot