import { ResponsiveTreeMapHtml } from '@nivo/treemap'
import React from 'react'
import { formattedNumberLabel } from '../../util/formattedLabels'
import EmptyStateText from '../EmptyStateText'

/**
 * Defines node color details
 */
function nodeColor( name, fill, text, fontWeight ){
    return { name, fill, text, fontWeight }
}

/**
 * Utility class to manage TreeMap color schemes
 */
class NodeColors {
    constructor() {
        this.getColorSchemeArray()
    }

    /**
     * Map colors definitions to an array of fill colors
     */
    getColorSchemeArray(){
        if (! this.colorScheme ){
            this.colorScheme = Object.values(this.colors)
                .map( scheme => scheme.fill )
        }
        return this.colorScheme
    }

    /**
     * Retrieve a color mapping object for the given datum
     * @param {Object} data Node data object
     */
    get( data ){
        const color = NODE_COLORS.colors[data.range]
        if( !color ){
            console.warn(`Unmapped color ${data.range}`)
        }

        return color
    }

    /** Map of color definitions */
    colors = {
        high: nodeColor( 'high', '#560066', 'white', 700 ),
        medium: nodeColor( 'medium', '#A772B0', 'black', 400 ),
        low: nodeColor( 'low', '#FADDFF', 'black', 400 )
    }

    /**
     * Map node data to its color scheme index
     * @param {Object} node
     */
    nodeColorMap(data){
        const color = NODE_COLORS.get(data)
        return color.fill
    }
}

const NODE_COLORS = new NodeColors()

/**
 * Scans the data, setting an 'id' value equal to the index to avoid element errors
 * @param {Object} data 3.1 data object
 */
function checkData(data){
    // prepare a Set to find names which already exist
    const names = new Set()

    data?.children?.forEach( (element, index) => {
        // Set id = index for each of the data.children elements
        // This ensures a unique 'id'
        element.id = index

        // Log if the elements 'name' (used as another ID in the chart) is already present
        if( names.has(element.name) ){
            console.warn(`Data already includes "${element.name}"`)
        }

        names.add(element.name)
    })

    // Order data descending by total
    data.children = data.children.sort( function(a,b) {
        return b.total - a.total
    }).slice(0, 25)

    return data
}

const HireStarsTreeMap = ({ data }) => (
    <>
        { !data || data.length === 0
            ? <div>
                <EmptyStateText/>
              </div>
            : <ResponsiveTreeMapHtml
                data={checkData(data)}
                identity="name"
                value="total"
                valueFormat={(value)=>formattedNumberLabel(value)}
                colors={node => NODE_COLORS.nodeColorMap(node.data)}
                enableLabel={true}
                label={
                    node => {
                        // Limit label to 20 characters
                        const len = 20
                        const trimmedName = node.data.name.length > len
                                            ? node.data.name.substring(0, len-3) + '...'
                                            : node.data.name
                        return `${trimmedName}`
                    }
                }
                labelTextColor={ node => NODE_COLORS.get(node.data).text }
                margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                leavesOnly
                nodeOpacity={1}
                innerPadding={5}
                tile="binary"
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            .6
                        ]
                    ]
                }}
            />
        }
    </>
)

export default HireStarsTreeMap
