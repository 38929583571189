import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import theme from './assets/material_ui_theme'
import { Auth0Provider } from '@auth0/auth0-react'
import { CookiesProvider } from 'react-cookie'
import { NavigationHistoriesProvider } from './providers/NavHistoriesContext'
import { MsaRegionSelectionProvider } from './hooks/useMsaRegionSelection'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Auth0Provider
          domain={
            process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN ||
            process.env.REACT_APP_AUTH0_DOMAIN ||
            ''
          }
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
          redirectUri={`${window.location.origin}/redirect`}
          audience={`https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`}
          scope='read:users read:current_user read:user_idp_tokens read:current_user_metadata update:current_user_metadata'
          cacheLocation='localstorage'
        >
          <CookiesProvider>
            <NavigationHistoriesProvider>
              <MsaRegionSelectionProvider>
                <App />
              </MsaRegionSelectionProvider>
            </NavigationHistoriesProvider>
          </CookiesProvider>
        </Auth0Provider>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
