import { useEffect, useState } from 'react'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import LinearProgress from '@mui/material/LinearProgress'
import { Grid, Typography, Switch, Box, Tooltip } from '@mui/material'
import ProgressBar from '@ramonak/react-progress-bar'
import { styled } from '@mui/material/styles'
import { useNavigationHistories } from 'src/providers/NavHistoriesContext'

import styles from '../../styles/DemographicsTable.module.css'
import { formattedPercent } from '../../util/formattedLabels'
import { regionNameFormatting } from '../../util/util'

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}))

function createData(
  jobTitle,
  openRoles,
  avSalary,
  employers,
  noDegreeRequired,
  numStars,
  infoText
) {
  let normalizeNoDegreeRequired =
    Number(noDegreeRequired.replace('%', '')) > 100
      ? 100
      : Number(noDegreeRequired.replace('%', ''))
  return {
    jobTitle,
    openRoles,
    avSalary,
    employers,
    noDegreeRequired: normalizeNoDegreeRequired,
    numStars,
    infoText
  }
}

function formattedSalary(number) {
  const roundedNumber = Math.round(number / 1000) * 1000
  return roundedNumber.toLocaleString()
}

function formattedNumber(number) {
  switch (true) {
    case number >= 1000000:
      return `${Math.round(number / 10000) / 100} million`
    case number >= 10000:
      return `${Math.round(number / 1000)} K`
    case number >= 1000:
      return `${Math.round(number / 100) / 10} K`
    default:
      return `${Math.round(number)}`
  }
}
export default function HireStarsTable({
  tableData,
  publicSectorData = [],
  region,
  tableLoading
}) {
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [publicSwitch, setPublicSwitch] = useState(false)
  const { navHistoriesValue } = useNavigationHistories()

  const regionName = region.region_name
  const publicDataExists = [2, 3].includes(region.geo_type_id)
  const nameColumnsTable = [
    {
      name: 'Job title / median wage',
      style: {
        color: 'var(--neutral---gray-900)',
        fontSize: '14px',
        padding: '1.5rem',
        width: '25%',
        fontFamily: 'DM Sans'
      }
    },
    {
      name: 'Open roles',
      style: {
        color: 'var(--neutral---gray-900)',
        fontSize: '14px',
        padding: '1.5rem 1rem 1.5rem 1rem',
        fontFamily: 'DM Sans'
      }
    },
    {
      name: 'Top employers',
      style: {
        color: 'var(--neutral---gray-900)',
        fontSize: '14px',
        padding: '1.5rem 1rem 1.5rem 1rem',
        width: '40%',
        fontFamily: 'DM Sans'
      }
    },
    {
      name: 'STARs in role',
      style: {
        color: 'var(--neutral---gray-900)',
        fontSize: '14px',
        padding: '1.5rem 1rem 1.5rem 1rem',
        fontFamily: 'DM Sans'
      }
    },
    {
      name: 'No B.A/B.S req.',
      style: {
        color: 'var(--neutral---gray-900)',
        fontSize: '14px',
        padding: '1.5rem 1rem 1.5rem 1rem',
        fontFamily: 'DM Sans'
      }
    }
  ]

  function switchChange(event) {
    setPublicSwitch(event.target.checked)
    window.dataLayer.push({
      event: 'jobs_dashboard_public_toggle',
      toggled: event.target.checked,
      region: regionName
    })
  }

  function generateToggleLabel() {
    const stateRegion = navHistoriesValue?.find(
      (data) => data.geo_type_id === 2
    )
    const region = navHistoriesValue[navHistoriesValue.length - 1]?.geo_type_id
      ? navHistoriesValue[navHistoriesValue.length - 1]
      : stateRegion
    if (region?.geo_type_id === 2 || !region?.geo_type_id) {
      return `${regionNameFormatting(
        region?.region_name,
        region?.region_code,
        region?.geo_type_id
      )} jobs`
    } else {
      return `Limit to ${regionNameFormatting(
        stateRegion?.region_name,
        stateRegion?.region_code,
        stateRegion?.geo_type_id
      )} jobs located in ${region.region_name}`
    }
  }

  function rowProcessing(data, pubData = false) {
    if (data.length === 0) {
      return data
    }

    const employers = pubData ? 'top_public_employers' : 'top_employers'
    const jobPostings = pubData ? 'public_job_postings' : 'job_postings'

    return data
      .sort((a, b) => {
        return pubData
          ? b.public_job_postings - a.public_job_postings
          : b.job_postings - a.job_postings
      })
      .map((rowElement) => {
        return createData(
          rowElement.job_title,
          formattedNumber(rowElement[jobPostings]),
          formattedSalary(rowElement.median_annual_wage),
          rowElement[employers],
          formattedPercent(rowElement.no_degree_required),
          formattedPercent(rowElement.stars_in_this_role)
        )
      })
  }

  const CompanyCell = ({ companies }) => {
    const companyList = companies.join(', ')
    const truncatedCompanyList =
      companyList.length > 125 ? `${companyList.slice(0, 120)}...` : companyList

    const tooltipContent = (
      <Box>
        <Typography variant='body2'>Top employers:</Typography>
        {companies.map((company, index) => (
          <Typography key={index} variant='body2'>
            {index + 1}- {company}
          </Typography>
        ))}
      </Box>
    )

    return (
      <Tooltip title={tooltipContent} arrow>
        <Typography
          variant='body2'
          style={{
            fontFamily: 'DM Sans',
            fontSize: '14px'
          }}
        >
          {truncatedCompanyList}
        </Typography>
      </Tooltip>
    )
  }

  useEffect(() => {
    setLoading(true)
    if (publicSwitch) {
      setRows(rowProcessing(publicSectorData, true))
    } else {
      setRows(rowProcessing(tableData))
    }
    setLoading(false)
  }, [tableData, publicSectorData, publicSwitch])

  useEffect(() => {
    setPublicSwitch(false)
  }, [region])

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      style={{
        borderRadius: '1.5rem',
        boxShadow:
          '0px 2px 6px 0px rgba(61, 61, 61, 0.10), 0px 0px 4px 0px rgba(61, 61, 61, 0.06)',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.25rem'
      }}
    >
      <Grid container spacing={1} sx={{ padding: '1.5rem 1.5rem 1rem 1.5rem' }}>
        <Grid item xs={12}>
          <Typography variant='h6' sx={{ fontFamily: 'DM Sans' }}>
            What roles offer the most opportunities for STARs in{' '}
            {regionNameFormatting(
              region?.region_name,
              region?.region_code,
              region?.geo_type_id
            )}
            ?
          </Typography>
          <Typography
            variant='body1'
            style={{ paddingTop: '0.5rem', fontFamily: 'DM Sans' }}
          >
            These in-demand roles can offer mobility for STARs. They also pay
            above the median wage, and most listings don’t require a bachelor’s
            degree.
          </Typography>
          {publicDataExists && (
            <>
              <IOSSwitch
                sx={{ m: 1 }}
                onChange={switchChange}
                checked={publicSwitch}
              />
              <Typography variant='small'>
                {navHistoriesValue.length > 0 ? generateToggleLabel() : ''}
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
      <Table aria-label='DM Sans'>
        <TableHead
          style={{
            background: 'var(--neutral---gray-100)',
            borderTop: '0.125rem solid rgba(61, 61, 61, 0.10)',
            borderBottom: '0.125rem solid rgba(61, 61, 61, 0.10)',
            fontFamily: 'DM Sans'
          }}
        >
          <TableRow
            style={{
              fontSize: '0.875rem',
              fontFamily: 'DM Sans'
            }}
          >
            {nameColumnsTable.map((column, key) => {
              return (
                <TableCell key={key} style={column.style}>
                  {column.name}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        {!tableLoading && !loading && (
          <TableBody>
            {rows.length > 0 &&
              rows.map((row) => (
                <TableRow key={row.jobTitle}>
                  <TableCell
                    component='th'
                    scope='row'
                    style={{
                      padding: '0.25rem 1rem 0.25rem 1.5rem'
                    }}
                  >
                    <div className={styles.columnTableJobTitle}>
                      <p>{row.jobTitle}</p>
                      <p>${row.avSalary}</p>
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      background: 'var(--neutral---gray-50)'
                    }}
                  >
                    <div className={styles.rowOpenRoles}>
                      <p>{row.openRoles}</p>
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      padding: '1rem 1rem 1rem 1rem',
                      fontFamily: 'DM Sans',
                      fontSize: '14px'
                    }}
                  >
                    <Grid container alignItems='center'>
                      <Grid item>
                        <CompanyCell companies={row.employers} />
                      </Grid>
                    </Grid>
                  </TableCell>

                  <TableCell style={{ padding: '1.5rem 1rem 1.5rem 1rem' }}>
                    <div className={styles.containerProgressBar}>
                      <div>
                        <p>{row.numStars}</p>
                      </div>
                      <div className={styles.progressBar}>
                        <ProgressBar
                          completed={row.numStars.replace('%', '')}
                          width={'6.75rem'}
                          height={'0.25rem'}
                          borderRadius={'0.5rem'}
                          baseBgColor='#E5E7EB'
                          bgColor={'#8E4B9D'}
                          isLabelVisible={false}
                        />
                      </div>
                    </div>
                  </TableCell>

                  <TableCell style={{ padding: '1.5rem 1rem 1.5rem 1rem' }}>
                    <div className={styles.containerProgressBar}>
                      <div>
                        <p>{row.noDegreeRequired + '%'}</p>
                      </div>
                      <div className={styles.progressBar}>
                        <ProgressBar
                          completed={row.noDegreeRequired}
                          width={'6.75rem'}
                          height={'0.25rem'}
                          borderRadius={'0.5rem'}
                          baseBgColor='#E5E7EB'
                          bgColor={'#6CC24A'}
                          isLabelVisible={false}
                        />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        )}
      </Table>
      {!tableLoading && !loading && !publicSwitch && rows.length === 0 && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          sx={{ pt: 16, pb: 8 }}
        >
          <Typography variant='body'>
            No data available for this region.
          </Typography>
        </Box>
      )}
      {!tableLoading && !loading && publicSwitch && rows.length === 0 && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          sx={{ pt: 16, pb: 8 }}
        >
          <Typography variant='body'>
            No public sector jobs found for this region.
          </Typography>
        </Box>
      )}
      {tableLoading && (
        <div style={{ height: '40rem' }}>
          <LinearProgress color='primary' />
        </div>
      )}

      <div className={styles.footerTable}>
        <p>
          <a href='data-guidance?region=national#1.3'>Data sources</a>
        </p>
      </div>
    </TableContainer>
  )
}
